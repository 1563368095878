import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'
import axios from "axios"
export default class extends Controller {
  static targets = [ "step","brands","brandValue","categories","categoryValue","brandModels","brandModelValue","brandModelName","services","serviceValue","basket","dates","contacts","main"]
  connect() {
    const setHeight = () => {
      if(document.activeElement.tagName.toLowerCase() == 'input') {
        return;
      }
      setTimeout(function(){
        document.querySelector(".mainform_wrapper").style = "height: "+(window.innerHeight - 43) + "px !important;"
        document.querySelector(".appointment").style = "min-height: "+(window.innerHeight - 43) + "px !important;"
      },200)
    };
    // define mobile screen size:
    let deviceWidth = window.matchMedia("(max-width: 640px)");
    if (deviceWidth.matches) {
    // set an event listener that detects when innerHeight changes:
        window.addEventListener("resize", setHeight);
        setHeight();
        var element = document.getElementById("empty-div")
        element.scrollIntoView();
    }
    var url_string = window.location.href;
    var url = new URL(url_string);
    var source = url.searchParams.get('source');
    var referrer_url = url.searchParams.get('referrer_url');
    var shop_url = url.searchParams.get('shop_url');
    if(url.searchParams.get('created')){
      if(url.searchParams.get('created') == 'true'){
        this.done();
        return;
      }
    }
    if(source)
      document.cookie = 'source=' + source;
    if(referrer_url)
      document.cookie = 'referrer_url=' + referrer_url;
    if(shop_url)
      document.cookie = 'shop_url=' + shop_url;
    if(url.searchParams.get('device-model') || url.searchParams.get('device-model-id')){
      const device_model = url.searchParams.get('device-model') ? url.searchParams.get('device-model') : null;
      const device_model_id = url.searchParams.get('device-model-id') ? url.searchParams.get('device-model-id') : null;
      axios.get(`${this.getApiLink()}/api/get_step_data.json`,{params:{step:'services',device_model: device_model,device_model_id: device_model_id,store_id: this.getStoreId()}}).then(res=>{
        if(res.status == 200){
          this.stepTarget.innerHTML = '4';
          document.getElementById("brand").classList.add("mainform_filled")
          document.getElementById("category").classList.add("mainform_filled")
          document.getElementById("model").classList.add("mainform_filled")
          this.servicesTarget.classList.remove("d-none")
          document.getElementById("service").classList.add("mainform_active")
          this.servicesTarget.innerHTML = res.data.html
          this.servicesTarget.style.left = "calc(" + document.getElementById('service').getBoundingClientRect().left + "px - 5vw)"
          this.servicesTarget.style.top = '80px'
          document.getElementById("title").innerText = "Choose Service"
          this.brandValueTarget.innerText = document.getElementById('brandId').dataset.brandId;
          this.categoryValueTarget.innerText = document.getElementById('categoryId').dataset.categoryId;
          this.brandModelValueTarget.innerText = document.getElementById('brandModelId').dataset.brandmodelId;
          this.brandModelNameTarget.innerHTML = document.getElementById('brandModelId').dataset.brandmodelName;
          try{
            document.getElementById("brand").children[0].children[0].children[1].innerText = JSON.parse(document.getElementById("brandId").dataset.brandName)
            document.getElementById("category").children[0].children[0].children[1].innerText = JSON.parse(document.getElementById("categoryId").dataset.categoryName)
            document.getElementById("model").children[0].children[0].children[1].innerText = JSON.parse(document.getElementById("brandModelId").dataset.brandmodelName)
          }
          catch(e){}
          if(url.searchParams.get('alert') && url.searchParams.get('alert') == 'false'){
          }
          else{
          // Swal.fire('Model Found',"We have found "+this.brandModelNameTarget.innerHTML +". If it isn't you enter select from Model.", "success");
          }
        }
        else{
          Swal.fire('Model Not Found','Sorry! We are not offering any services of '+ device_model, "error");
        }
      }).catch(res => {
        Swal.fire('Model Not Found','Sorry! We are not offering any services of '+ device_model, "error");
      })
    }
    else{
      if(this.stepTarget.innerHTML != '0')
        this.stepTarget.innerHTML = parseInt(this.stepTarget.innerHTML) - 1;
      this.proceed(undefined,false);
    }
  }

  proceed(event,f=true){
    var step = this.stepTarget.innerHTML
    const clickStep = f ? event.currentTarget.dataset.currentStep : undefined;
    switch(step){
      case '0':
        this.stepTarget.innerHTML = '1';
        this.getBrands();
        break;
      case '1':
        if(clickStep){
          if(step < clickStep){
            this.brandsTarget.classList.add("shake");
            setTimeout(()=>{this.brandsTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
          else{
            this.stepTarget.innerHTML = parseInt(clickStep) - 1;
            this.proceed(event,false);
            return;
          }
        }
        this.stepTarget.innerHTML = '2';
        if(f){
          this.brandValueTarget.innerHTML = event.currentTarget.dataset.brandId;
          document.getElementById("brand").children[0].children[0].children[1].innerText = JSON.parse(event.currentTarget.dataset.brandName)
        }
        this.getCatgories();
        break;
      case '2':
        if(clickStep){
          if(step < clickStep){
            this.categoriesTarget.classList.add("shake");
            setTimeout(()=>{this.categoriesTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
          else{
            this.categoriesTarget.classList.add("d-none");
            document.getElementById("category").classList.remove("mainform_active");
            this.stepTarget.innerHTML = parseInt(clickStep) - 1;
            this.proceed(event,false);
            return;
          }
        }
        this.stepTarget.innerHTML = '3';
        if(f){
          this.categoryValueTarget.innerHTML = event.currentTarget.dataset.categoryId
          document.getElementById("category").children[0].children[0].children[1].innerText = JSON.parse(event.currentTarget.dataset.categoryName)
        }
        this.getBrandModels();

        break;
      case '3':
        if(clickStep){
          if(step < clickStep){
            this.brandModelsTarget.classList.add("shake");
            setTimeout(()=>{this.brandModelsTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
          else{
            this.brandModelsTarget.classList.add("d-none");
            document.getElementById("model").classList.remove("mainform_active");
            this.stepTarget.innerHTML = parseInt(clickStep) - 1;
            this.proceed(event,false);
            return;
          }
        }
        this.stepTarget.innerHTML = '4';
        if(f){
          this.brandModelNameTarget.innerText = event.currentTarget.innerText;
          this.brandModelValueTarget.innerHTML = event.currentTarget.dataset.modelId;
          document.getElementById("model").children[0].children[0].children[1].innerText = JSON.parse(event.currentTarget.dataset.modelName)
        }
        this.getServices();

        break;
      case '4':
        if(clickStep){
          if(step < clickStep){
            this.servicesTarget.classList.add("shake");
            setTimeout(()=>{this.servicesTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
          else{
            this.servicesTarget.classList.add("d-none");
            document.getElementById("service").classList.remove("mainform_active")
            this.stepTarget.innerHTML = parseInt(clickStep) - 1;
            this.proceed(event,false);
            return;
          }
        }
        this.addBasket();
        this.getDates();
        this.stepTarget.innerHTML = '5';
        break;
      case '5':
        if(clickStep){
          if(step < clickStep){
            this.datesTarget.classList.add("shake");
            setTimeout(()=>{this.datesTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
          else{
            this.datesTarget.classList.add("d-none");
            document.getElementById("date").classList.remove("mainform_active")
            this.stepTarget.innerHTML = parseInt(clickStep) - 1;
            this.proceed(event,false);
            return;
          }
        }
        this.saveDate(event);
        this.displayContactForm();
        this.stepTarget.innerHTML = '6';
        break;
      case '6':
        if(clickStep){
          if(step < clickStep){
            this.contactsTarget.classList.add("shake");
            setTimeout(()=>{this.contactsTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
          else{
            this.contactsTarget.classList.add("d-none");
            document.getElementById("contact").classList.remove("mainform_active")
            this.stepTarget.innerHTML = parseInt(clickStep) - 1;
            this.proceed(event,false);
            return;
          }
        }
        document.getElementById("brand").children[0].children[0].children[1].innerText = ""
        document.getElementById("category").children[0].children[0].children[1].innerText = ""
        document.getElementById("model").children[0].children[0].children[1].innerText = ""
        this.displayBasket();
        this.stepTarget.innerHTML = '7';
        break;
      case '7':
      case '8':
        if(clickStep){
          if(step > clickStep){
            this.basketTarget.classList.add("shake");
            setTimeout(()=>{this.basketTarget.classList.remove("shake");}, 1000)
            return;
          }
          else if(step == clickStep){
            return;
          }
        }
        var data = this.getDataFromLocalStorage();
        data['id'] = this.getLSWithDefaultExpiry("Ticket_id");
          axios.post("/payment.json", { data: data }).then((res) => {
            if(res.data.payment){
              window.location.replace(res.data.url);
            }
            else{
              this.done();
            }
          })
        break;
      default:
        break;
    }
  }
  getBrands(){
    document.getElementById('brandload').addEventListener("DOMNodeInserted",() => {
      this.checkError()
    })
    var html = document.getElementById("brand").innerHTML;
    document.getElementById('brand').children[0].remove();
    document.getElementById("brand").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.get(`${this.getApiLink()}/api/get_step_data.json`,{params:{step:'brands',id:this.getStoreId()}}).then(res=>{
      if(res.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('brand').children[0].remove();
        document.getElementById('brand').innerHTML = html;
        this.brandsTarget.classList.remove("d-none")
        document.getElementById("brand").classList.add("mainform_active")
        this.brandsTarget.innerHTML = res.data.html
        this.brandsTarget.style.left =  document.getElementById('brand').getBoundingClientRect().left
        this.brandsTarget.style.top = '80px'
        document.getElementById("title").innerText = "Choose Brand"
        if(window.screen.width >= 620 )
        {
          this.brandsTarget.scrollIntoView();
        }
      }
    })
  }
  checkError(){
    const loc = window.location.href;
    const path = loc.substr(0, loc.lastIndexOf('/') + 1);
    Array.from(document.getElementsByClassName("brand_logo")).map(element => {
      element.addEventListener("error", () => {
        element.src = path + 'assets/category/other.svg';
      })
    })
  }
  getCatgories(){
    document.getElementById('categoryload').addEventListener("DOMNodeInserted",() => {
      this.checkError()
    })
    var html = document.getElementById("category").innerHTML;
    document.getElementById('category').children[0].remove();
    document.getElementById("category").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.get(`${this.getApiLink()}/api/get_step_data.json`,{params:{step:'categories',id: this.getStoreId(), brand_id: this.brandValueTarget.innerHTML}}).then(res=>{      if(res.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('category').children[0].remove();
        document.getElementById('category').innerHTML = html;
        this.brandsTarget.classList.add("d-none")
        document.getElementById("brand").classList.remove("mainform_active")
        document.getElementById("brand").classList.add("mainform_filled")
        this.categoriesTarget.classList.remove("d-none")
        document.getElementById("category").classList.add("mainform_active")
        this.categoriesTarget.innerHTML = res.data.html
        this.categoriesTarget.style.left = "calc("+ document.getElementById('category').getBoundingClientRect().left + "px - 5vw)"
        this.categoriesTarget.style.top = '80px'
        document.getElementById("title").innerText = "Choose Type"
        if(window.screen.width >= 620 )
        {
          this.categoriesTarget.scrollIntoView();
        }
      }
    })
  }
  getBrandModels(){
    var html = document.getElementById("model").innerHTML;
    document.getElementById('model').children[0].remove();
    document.getElementById("model").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.get(`${this.getApiLink()}/api/get_step_data.json`,{params:{step:'brand_models',id: this.categoryValueTarget.innerHTML, brand_id: this.brandValueTarget.innerHTML}}).then(res=>{
      if(res.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('model').children[0].remove();
        document.getElementById('model').innerHTML = html;
        this.categoriesTarget.classList.add("d-none")
        document.getElementById("category").classList.remove("mainform_active")
        document.getElementById("category").classList.add("mainform_filled")
        this.brandModelsTarget.classList.remove("d-none")
        document.getElementById("model").classList.add("mainform_active")
        this.brandModelsTarget.innerHTML = res.data.html
        this.brandModelsTarget.style.left = "calc(" + document.getElementById('model').getBoundingClientRect().left + "px - 5vw)"
        this.brandModelsTarget.style.top = '80px'
        document.getElementById("title").innerText = "Choose Model"
        if(window.screen.width >= 620 )
        {
          this.brandModelsTarget.scrollIntoView();
        }
      }
    })
  }
  getServices(){
    var html = document.getElementById("service").innerHTML;
    document.getElementById('service').children[0].remove();
    document.getElementById("service").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.get(`${this.getApiLink()}/api/get_step_data.json`,{params:{step:'services',id: this.brandModelValueTarget.innerHTML}}).then(res=>{
      if(res.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('service').children[0].remove();
        document.getElementById('service').innerHTML = html;
        this.brandModelsTarget.classList.add("d-none")
        document.getElementById("model").classList.remove("mainform_active")
        document.getElementById("model").classList.add("mainform_filled")
        this.servicesTarget.classList.remove("d-none")
        document.getElementById("service").classList.add("mainform_active")
        this.servicesTarget.innerHTML = res.data.html
        this.servicesTarget.style.left = "calc(" + document.getElementById('service').getBoundingClientRect().left + "px - 5vw)"
        this.servicesTarget.style.top = '80px'
        document.getElementById("title").innerText = "Select Services"
        if(window.screen.width >= 620 )
        {
          this.servicesTarget.scrollIntoView();
        }
        try{
          document.getElementById("brand").children[0].children[0].children[1].innerText = JSON.parse(document.getElementById("brandId").dataset.brandName)
          document.getElementById("category").children[0].children[0].children[1].innerText = JSON.parse(document.getElementById("categoryId").dataset.categoryName)
          document.getElementById("model").children[0].children[0].children[1].innerText = JSON.parse(document.getElementById("brandModelId").dataset.brandmodelName)
        }
        catch(e){

        }
      }
    })
  }
  getDates(){
    var html = document.getElementById("date").innerHTML;
    document.getElementById('date').children[0].remove();
    document.getElementById("date").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.get(`${this.getApiLink()}/api/get_step_data.json`,{params:{step:'dates',id: this.getStoreId()}}).then(res=>{
      if(res.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('date').children[0].remove();
        document.getElementById('date').innerHTML = html;
        this.servicesTarget.classList.add("d-none");
        document.getElementById("service").classList.remove("mainform_active");
        document.getElementById("service").classList.add("mainform_filled")
        this.datesTarget.classList.remove("d-none");
        document.getElementById("date").classList.add("mainform_active")
        document.getElementById("title").innerText = "Select Appointment Date"
        this.datesTarget.innerHTML = res.data.html
        if(window.screen.width >= 620 )
        {
          this.datesTarget.scrollIntoView();
        }
      }
    })
  }

  toggleSelect(event){
    var element = event.currentTarget.children[2]
    element.innerText == "check_box" ? element.innerText = "check_box_outline_blank" : element.innerText = "check_box"
    event.currentTarget.classList.toggle("selected-service");
    element.classList.toggle("d-none")
  }

  serviceNext(event){
    if(document.getElementsByClassName("selected-service").length > 0) {
      this.proceed(event,true);
    }
    else {
      this.servicesTarget.classList.add("shake");
      var ele = document.getElementById("btn-alert")
      setTimeout(()=>{
        this.servicesTarget.classList.remove("shake");
        if(ele.classList.contains('d-none'))
          ele.classList.remove('d-none')
      }, 1000)
    }
  }

  addBasket(event){
    var serviceArray = []
    Array.from(document.getElementsByClassName("selected-service")) .map((item)=>{
      serviceArray.push(JSON.parse(item.dataset.serviceData))
    })
    var data = {
      brand_id: this.brandValueTarget.innerText,
      category_id: this.categoryValueTarget.innerText,
      category_name: document.getElementById('categoryId').dataset.categoryName,
      brand_model_id: this.brandModelValueTarget.innerText,
      brand_model_name: this.brandModelNameTarget.innerText,
      services: serviceArray,
    }
    localStorage.setItem("Ticket Data", JSON.stringify(data))
  }

  displayContactForm(){
    var html = document.getElementById("contact").innerHTML;
    document.getElementById('contact').children[0].remove();
    document.getElementById("contact").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.get('/contact.*', { data: [] }).then(res=>{
      if(res.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('contact').children[0].remove();
        document.getElementById('contact').innerHTML = html;
        this.datesTarget.classList.add("d-none");
        document.getElementById("date").classList.remove("mainform_active");
        document.getElementById("date").classList.add('mainform_filled');
        this.contactsTarget.classList.remove("d-none");
        document.getElementById("contact").classList.add("mainform_active")
        document.getElementById("title").innerText = "Contact Form"
        this.contactsTarget.innerHTML = res.data.html
        var customer = JSON.parse(localStorage.getItem("Customer Details"))
        document.getElementById("phone-no").value = customer?.phone_number ? customer.phone_number.trim() : null;
        document.getElementById("first-name").value = customer?.name?.split(' ')[0] ? customer.name.split(' ')[0] : null;
        document.getElementById("last-name").value = customer?.name?.split(' ')[1] ? customer.name.replace(customer.name.split(' ')[0],'').trim() : null;
        document.getElementById("e-mail").value = customer?.email ? customer.email.trim() : null;
        if(window.screen.width >= 620 )
        {
          this.contactsTarget.scrollIntoView();
        }
      }
    })
  }
  submitTicket(event){
    var ele = document.getElementById("phone-no");
    var value = ele.value;
    if( value ) {
      var customer = JSON.parse(localStorage.getItem("Customer Details"))
      localStorage.setItem("Customer Details",JSON.stringify({name: customer?.name,email:customer?.email,phone_number:value,address:customer?.address}))
      var data = this.getDataFromLocalStorage();
      if(this.getLSWithDefaultExpiry("Ticket_id")){
      this.updateTicket(data);
      }
      else{
        this.createTicket(data);
      }
    }
    else{
      ele.classList.add('border-danger');
      let span = document.createElement('span');
      span.innerText = 'Please Enter Phone No.';
      span.classList.add("text-danger");
      span.classList.add("fs-6");
      ele.parentNode.insertBefore(span, ele.nextSibling);
    }
  }
  updateCustomerDetails(event){
    var fnEle = document.getElementById('first-name');
    var lnEle = document.getElementById('last-name');
    var emailEle = document.getElementById('e-mail');
    var fname = fnEle.value
    var lname = lnEle.value
    var email = emailEle.value
    if(!fname){
      fnEle.classList.add('border-danger');
      let span = document.createElement('span');
      span.innerText = 'First Name Must Exist';
      span.classList.add("text-danger");
      span.classList.add("fs-6");
      if(fnEle.parentElement.childElementCount == 1)
        fnEle.parentNode.insertBefore(span, fnEle.nextSibling);
    }
    else{
      fnEle.classList.remove('border-danger');
      fnEle.parentElement.children[1]?.remove()
    }
    if(!lname){
      lnEle.classList.add('border-danger');
      let span = document.createElement('span');
      span.innerText = 'Last Name Must Exist';
      span.classList.add("text-danger");
      span.classList.add("fs-6");
      if(lnEle.parentElement.childElementCount == 1)
        lnEle.parentNode.insertBefore(span, lnEle.nextSibling);
    }
    else{
      lnEle.classList.remove('border-danger');
      lnEle.parentElement.children[1]?.remove()
    }
    if(!email){
      emailEle.classList.add('border-danger');
      let span = document.createElement('span');
      span.innerText = 'Email Must Exist';
      span.classList.add("text-danger");
      span.classList.add("fs-6");
      if(emailEle.parentElement.childElementCount == 1)
        emailEle.parentNode.insertBefore(span, emailEle.nextSibling);
    }
    else{
      emailEle.classList.remove('border-danger');
      emailEle.parentElement.children[1]?.remove()
    }
    if(!email || !lname || !fname){
      return
    }

    var customer = JSON.parse(localStorage.getItem("Customer Details"))
    customer["name"] = fname + ' ' + lname;
    customer["email"] = email;
    localStorage.setItem("Customer Details",JSON.stringify(customer))
    var ticket_id = this.getLSWithDefaultExpiry("Ticket_id")
    if(ticket_id){
      var html = document.getElementById("contact").innerHTML;
      document.getElementById('contact').children[0].remove();
      document.getElementById("contact").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
      document.body.style = "cursor: wait;"
      this.mainTarget.style = "pointer-events: none;"
      axios.patch(`${this.getApiLink()}/tickets/${ticket_id}/update_customer_details.json`,{data:localStorage.getItem("Customer Details")}).then(res=>{
        if(res.status == 200){
          document.body.style = "cursor: auto;"
          this.mainTarget.style = "pointer-events: auto;"
          document.getElementById('contact').children[0].remove();
          document.getElementById('contact').innerHTML = html;
          this.proceed(null,false);
        }
        else{
          alert("reload page something went wrong");
        }
      })
    }
  }
  displayBasket(){
    this.datesTarget.classList.add("d-none");
    this.servicesTarget.classList.add("d-none");
    this.brandModelsTarget.classList.add("d-none");
    this.categoriesTarget.classList.add("d-none");
    this.brandsTarget.classList.add("d-none");
    this.contactsTarget.classList.add("d-none");
    document.getElementById("contact").classList.remove("mainform_active");
    document.getElementById("contact").classList.add('mainform_filled');
    document.getElementById("date").classList.add('mainform_filled');
    document.getElementById("service").classList.add('mainform_filled');
    document.getElementById("model").classList.add('mainform_filled');
    document.getElementById("category").classList.add('mainform_filled');
    document.getElementById("brand").classList.add('mainform_filled');
    this.basketTarget.classList.remove("d-none");
    document.getElementById("basket").classList.add("mainform_active")
    axios.post('/basket.*', { data: localStorage }).then(res=>{
      if(res.status == 200){
        document.getElementById("title").innerText = "Basket"
        this.basketTarget.innerHTML = res.data.html
        document.getElementById("proceed").disabled = false;
        if(window.screen.width >= 620 )
        {
          this.basketTarget.scrollIntoView();
        }
      }
    })
  }
  createTicket(data){
    var card = true;
    var html = document.getElementById("contact").innerHTML;
    document.getElementById('contact').children[0].remove();
    document.getElementById("contact").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.post(`${this.getApiLink()}/tickets.json`, data ).then((res) => {
      if(res.data.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('contact').children[0].remove();
        document.getElementById('contact').innerHTML = html;
        this.setLSWithDefaultExpiry('Ticket_id',res.data.data.id);
        document.querySelector('.modal-body-step-1').classList.add("animate-out");
        setTimeout(function(){
          document.querySelector('.modal-body-step-1').classList.remove("d-block");
          document.querySelector('.modal-body-step-2').classList.add("animate-in");
          setTimeout(function(){
            document.querySelector('.modal-body-step-2').classList.add("d-block");
          },500)
        },500);
      }
    });
  }
  updateTicket(data){
    var html = document.getElementById("contact").innerHTML;
    document.getElementById('contact').children[0].remove();
    document.getElementById("contact").innerHTML = `<div class="d-flex h-100 align-items-center justify-content-center "><div class="spinner-border" role="status"></div></div>`
    document.body.style = "cursor: wait;"
    this.mainTarget.style = "pointer-events: none;"
    axios.patch(`${this.getApiLink()}/tickets/${this.getLSWithDefaultExpiry("Ticket_id")}/update_ticket.json`, data ).then((res) => {
      if(res.data.status == 200){
        document.body.style = "cursor: auto;"
        this.mainTarget.style = "pointer-events: auto;"
        document.getElementById('contact').children[0].remove();
        document.getElementById('contact').innerHTML = html;
        this.setLSWithDefaultExpiry('Ticket_id',res.data.data.id);
        document.querySelector('.modal-body-step-1').classList.add("animate-out");
        setTimeout(function(){
          document.querySelector('.modal-body-step-1').classList.remove("d-block");
          document.querySelector('.modal-body-step-2').classList.add("animate-in");
          setTimeout(function(){
            document.querySelector('.modal-body-step-2').classList.add("d-block");
          },500)
        },500);
      }
    });
  }

  removeBasketItem(event){
    // var name = event.currentTarget.dataset.modelName
    var data = localStorage.removeItem("Ticket Data")
    this.stepTarget.innerHTML = '0';
    this.basketTarget.classList.add("d-none");
    document.getElementById("basket").classList.remove("mainform_active");
    Array.from(document.querySelectorAll('.mainform_filled')).forEach((el) => el.classList.remove('mainform_filled'));
    this.proceed(event,false)
  }

  editBasketItem(event){
    // var name = event.currentTarget.dataset.modelName
    var data = JSON.parse(localStorage.getItem("Ticket Data"))
    localStorage.removeItem("Ticket Data")
    this.brandValueTarget.innerText = data["brand_id"]
    this.categoryValueTarget.innerText = data["category_id"]
    this.brandModelValueTarget.innerText = data["brand_model_id"]
    this.brandModelNameTarget.innerText = data["brand_model_name"],
    document.getElementById("basket").classList.remove("mainform_active");
    this.basketTarget.classList.add("d-none");
    this.stepTarget.innerHTML = '4';
    this.getServices()
  }

  showDates(event){
    // this.stepTarget.innerText = '6'
    // this.proceed(event,false);
  }
  loadDates(event){
    var dates = Array.from(event.currentTarget.parentNode.parentNode.children);
    var temp=0;
    if(dates[10].classList.contains('d-none')){
      temp = 10;
    }
    else if(dates[15].classList.contains('d-none')){
      temp = 15;
    }
    else if(dates[20].classList.contains('d-none')){
      temp = 20;
    }
    for(var i=temp; i<dates.length; i++){
      if( i == dates.length-1){
        dates[i].classList.add('d-none');
        break;
      }
      if( i == temp+5)
        break;
      dates[i].classList.remove('d-none')
    }
  }
  saveDate(event){
    if(event.currentTarget.classList.contains("date_not_available")){
      return;
    }
    localStorage.setItem("Selected Date",event.currentTarget.innerText.split('\n')[0])
    Array.from(event.currentTarget.parentNode.children).map(element => {
      if(element.classList.contains('selected-date')){
        element.classList.remove('selected-date')
      }
    })
    event.currentTarget.classList.add('selected-date');
  }
  cancel(){
    // window.location.href = window.location.origin
  }
  getApiLink(){
    return document.querySelector('meta[name="api_link"]').content
  }
  getStoreId(){
    return document.querySelector('meta[name="store_id"]').content
  }
  filterModel(event){
    this.removeFilterActive()
    var value = event.currentTarget.value.toLowerCase().trim()
    var count =Array.from(event.currentTarget.parentElement.parentElement.parentElement.children).length
    Array.from(event.currentTarget.parentElement.parentElement.parentElement.children).map((element,index) => {
      if(element.id == 'brand-model-phone-card' || index == 0 ){return}
      if(index > 0 && !element.innerText.toLowerCase().includes(value)){
        element.classList.add("d-none");
        count--;
      }
      else {
        count++;
        element.classList.remove('d-none');
      }
    })
    if(count<=2){
      document.getElementById('brand-model-phone-card').classList.remove('d-none')
    }
    else{
      document.getElementById('brand-model-phone-card').classList.add('d-none')
    }
  }
  filterSeries(event){
    if(event.currentTarget.classList.contains('border-warning')){
      Array.from(event.currentTarget.parentElement.parentElement.children).map((element,index) => {
        if(element.id == 'brand-model-phone-card' || index < 2){}
        else {element.classList.remove('d-none');}
      })
      event.currentTarget.classList.remove('border-warning','text-warning')
      return;
    }
    this.removeFilterActive()
    event.currentTarget.classList.add('border-warning','text-warning')
    var value = event.currentTarget.dataset.seriesName
    Array.from(event.currentTarget.parentElement.parentElement.children).map((element,index) => {
      if(element.id == 'brand-model-phone-card' || index < 2){return}
      if(index > 1 && !(element.dataset.modelSeries == value)){
        element.classList.add("d-none");
      }
      else {
        element.classList.remove('d-none');
      }
    })
  }
  removeFilterActive(){
    Array.from(document.querySelectorAll('.filter')).map(ele => {
      ele.classList.remove('border-warning','text-warning')
      ele.classList.add('border-secondary')
    })
  }
  filterData(event){
    var value = event.currentTarget.value.toLowerCase().trim()
    Array.from(event.currentTarget.parentElement.parentElement.parentElement.children).map((element,index) => {
      if(index > 0 && !element.children[0].children[1].children[0].innerText.toLowerCase().includes(value)){
        element.classList.add("d-none");
      }
      else{
        element.classList.remove("d-none");
      }
    })
  }
  scrollOverflowText(event){
    var element = event.currentTarget
    if(element.scrollWidth > element.clientWidth)
    {
      var diff = element.scrollWidth - element.clientWidth
      document.documentElement.style.setProperty('--change', diff*-1 + 'px');
      document.documentElement.style.setProperty('--time', diff/20 + 's');
      element.classList.add("overflow-data-hover")
      element.addEventListener("mouseout",function(event){
        event.currentTarget.classList.remove("overflow-data-hover")
      } )
    }
    else if(element.scrollWidth == element.clientWidth){
      element.classList.add("overflow-visible");
      element.addEventListener("mouseout",function(event){
        event.currentTarget.classList.remove("overflow-visible")
      } )
    }
  }
  getCookie(str){
    const result = document.cookie.split(';').filter(co => co.split('=')[0].trim() == str)[0]?.trim().split('=')[1];
    //delete cookie
    document.cookie = str + '=; Max-Age=-99999999;';
    return result;
  }
  getDataFromLocalStorage(){
    var services = [];
    var modelData = JSON.parse(localStorage.getItem("Ticket Data"));
    modelData.services.map(service => {
      services.push({id:service.id,quality:service.quality || 'Standard'});
    })
    var data = {
      services: services,
      time: localStorage.getItem('Selected Date'),
      customer: localStorage.getItem("Customer Details"),
      payment_method:  "online" ,
      store: this.getStoreId(),
      referrer: this.getCookie('referrer_url') || document.referrer,
      source: this.getCookie('source'),
      shop_url: this.getCookie('shop_url')
    };
    return data;
  }

  toggleSelect1(event){
    var data = JSON.parse(event.currentTarget.dataset.serviceData)
    var price = parseFloat(data["price"])
    if(event.currentTarget.classList.contains('selected-service')){
      this.toggleSelect(event);
      if(data['quality'] && data['quality'] == 'Premium'){
        data['price'] = Math.round(price/1.34)
      }
      else if(data['quality'] && data['quality'] == 'Geniune'){
        data['price'] = Math.round(price/1.90)
      }
      event.currentTarget.children[1].children[1].innerText = '£' + data["price"].toString()
      event.currentTarget.dataset.serviceData = JSON.stringify(data);
      return
    }
    this.toggleSelect(event);
    document.querySelector(".quality-modal-wrapper").classList.add('d-flex')
    var modal = document.querySelector(".quality-modal-1")
    modal.classList.add('d-block');
    modal.querySelector('.model-name').innerText = this.brandModelNameTarget.innerText
    modal.querySelector('.service-standard-price').innerText = Math.round(price)
    modal.querySelector('.service-premium-price').innerText = Math.round(price +(price*0.34))
    modal.querySelector('.service-geniune-price').innerText = Math.round(price +(price*0.90))
    let prevTargetId = event.currentTarget.id;
    modal.querySelector('.standard-card').querySelector('button').addEventListener("click",function handler1(e){
      this.updateServicePrice(prevTargetId,'Standard')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
    modal.querySelector('.premium-card').querySelector('button').addEventListener("click",function handler2(e){
      this.updateServicePrice(prevTargetId,'Premium')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
    modal.querySelector('.geniune-card').querySelector('button').addEventListener("click",function handler3(e){
      this.updateServicePrice(prevTargetId,'Geniune')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
  }
  toggleSelect2(event){
    var data = JSON.parse(event.currentTarget.dataset.serviceData)
    var price = parseFloat(data["price"])
    if(event.currentTarget.classList.contains('selected-service')){
      this.toggleSelect(event);
      if(data['quality'] && data['quality'] == 'Premium'){
        data['price'] = Math.round(price/1.34)
      }
      // else if(data['quality'] && data['quality'] == 'Geniune'){
      //   data['price'] = Math.round(price/1.90)
      // }
      event.currentTarget.children[1].children[1].innerText = '£' + data["price"].toString()
      event.currentTarget.dataset.serviceData = JSON.stringify(data);
      return
    }
    this.toggleSelect(event);
    document.querySelector(".quality-modal-wrapper").classList.add('d-flex')
    var modal = document.querySelector(".quality-modal-2")
    modal.classList.add('d-block');
    modal.querySelector('.model-name').innerText = this.brandModelNameTarget.innerText
    modal.querySelector('.service-standard-price').innerText = Math.round(price )
    modal.querySelector('.service-premium-price').innerText =  Math.round(price +(price*0.34))
    // modal.querySelector('.service-geniune-price').innerText = Math.round(price +(price*0.90))
    let prevTargetId = event.currentTarget.id;
    modal.querySelector('.standard-card').querySelector('button').addEventListener("click",function handler1(e){
      this.updateServicePrice(prevTargetId,'Standard')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
    modal.querySelector('.premium-card').querySelector('button').addEventListener("click",function handler2(e){
      this.updateServicePrice(prevTargetId,'Premium')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
    // modal.querySelector('.geniune-card').querySelector('button').addEventListener("click",function handler3(e){
    //   this.updateServicePrice(prevTargetId,'Geniune')
    //   e.stopImmediatePropagation();
    //   e.stopPropagation();
    // }.bind(this))
  }
  toggleSelect3(event){
    var data = JSON.parse(event.currentTarget.dataset.serviceData)
    var price = parseFloat(data["price"])
    if(event.currentTarget.classList.contains('selected-service')){
      this.toggleSelect(event);
      if(data['quality'] && data['quality'] == 'Premium'){
        data['price'] = Math.round(price/1.34)
      }
      // else if(data['quality'] && data['quality'] == 'Geniune'){
      //   data['price'] = Math.round(price/1.90)
      // }
      event.currentTarget.children[1].children[1].innerText = '£' + data["price"].toString()
      event.currentTarget.dataset.serviceData = JSON.stringify(data);
      return
    }
    this.toggleSelect(event);
    document.querySelector(".quality-modal-wrapper").classList.add('d-flex')
    var modal = document.querySelector(".quality-modal-3")
    modal.classList.add('d-block');
    modal.querySelector('.model-name').innerText = this.brandModelNameTarget.innerText
    modal.querySelector('.service-standard-price').innerText = Math.round(price)
    modal.querySelector('.service-premium-price').innerText = Math.round(price +(price*0.34))
    let prevTargetId = event.currentTarget.id;
    modal.querySelector('.standard-card').querySelector('button').addEventListener("click",function handler2(e){
      this.updateServicePrice(prevTargetId,'Standard')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
    modal.querySelector('.premium-card').querySelector('button').addEventListener("click",function handler3(e){
      this.updateServicePrice(prevTargetId,'Premium')
      e.stopImmediatePropagation();
      e.stopPropagation();
    }.bind(this))
  }
  updateServicePrice(id,type){
    this.hideServiceModal()
    window.scrollTo(0, 0);
    var serviceCard = document.getElementById(id)
    var data = JSON.parse(serviceCard.dataset.serviceData)
    var price = parseFloat(data["price"])
    var percent = 0
    switch(type){
      case 'Premium':
        percent = 0.34;
        break;
      case 'Geniune':
        percent = 0.90;
        break;
      default:
    }
    data["price"] = Math.round(price + (price*percent))
    data["quality"] = type
    serviceCard.children[1].children[1].innerText = '£' + data["price"].toString()
    serviceCard.dataset.serviceData = JSON.stringify(data);
  }
  done(){
    dataLayer.push({'event': 'transaction'})
    localStorage.removeItem("Ticket Data")
    localStorage.removeItem("Ticket_id")
    localStorage.removeItem("Selected Date")
    Swal.fire('Appointment Booked','Congratulations Your Appointment has been registered' ,"success").then(()=>{
      var ele = document.getElementById('redirect-link');
      if( document.referrer && document.referrer?.split('/')[2] != window.location.origin.split('/')[2]) {
        ele.href = document.referrer?.split('?')+ "?created=true"
      }
      else{
        ele.href = window.location.origin
      }
      ele.click();
    })
  }
  toggleServiceCard(event){
    var svg = event.currentTarget.parentElement.querySelector("svg")
    svg.style.transform = svg.style.transform == 'rotate(180deg)' ? 'rotate(0deg)': 'rotate(180deg)';
    var ele = event.currentTarget.parentElement
    if(ele.classList.contains("premium-card") || ele.classList.contains("geniune-card")){
      ele.querySelector("h4").classList.toggle('text-white')
      ele.querySelector("h5").classList.toggle('text-white')
    }
    ele.children[ele.children.length-3].children[1].classList.toggle("d-none")
    ele.style.backgroundColor = ele.style.backgroundColor == 'unset' ? ele.style.borderColor : 'unset'
    ele.children[ele.children.length-1].classList.toggle("button-mobile")
  }
  closeModal(event){
    if(event.target.classList.contains('quality-modal-wrapper')){
      this.hideServiceModal();
    }
  }
  hideServiceModal(){
    document.querySelector(".quality-modal-wrapper").classList.remove('d-flex')
    document.querySelector(".quality-modal-1").classList.remove('d-block')
    document.querySelector(".quality-modal-2").classList.remove('d-block')
    document.querySelector(".quality-modal-3").classList.remove('d-block')
  }
  backContactForm(event){
    document.querySelector('.modal-body-step-1').classList.remove("animate-out");
    document.querySelector('.modal-body-step-2').classList.remove("animate-in");
    setTimeout(function(){
      document.querySelector('.modal-body-step-2').classList.remove("d-block");
      document.querySelector('.modal-body-step-1').classList.add("animate-in");
      setTimeout(function(){
        document.querySelector('.modal-body-step-1').classList.add("d-block");
        document.querySelector('.modal-body-step-1').classList.remove("animate-in");
      },500)
    },500);
  }

  setLSWithDefaultExpiry(key, value) {
    const now = new Date();
    const expirationDate = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
    const item = { value: value, expiry: expirationDate.getTime() };
    localStorage.setItem(key, JSON.stringify(item));
  }

  getLSWithDefaultExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    try {
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
          // If the item is expired, remove it from localStorage
          localStorage.removeItem(key);
          return null;
      }
      return item.value;
    } catch (e) {
      localStorage.removeItem(key)
      return null;
    }
  }
}
