import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'
export default class extends Controller {
  static targets = [ "image" ]
  connect() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var source = url.searchParams.get('source');
    var referrer_url = url.searchParams.get('referrer_url');
    if(source)
      document.cookie = 'source=' + source;
    if(referrer_url)
      document.cookie = 'referrer_url=' + referrer_url;
    if(url.searchParams.get('created')){
      if(url.searchParams.get('created') == 'true'){
        dataLayer.push({'event': 'transaction'})
        Swal.fire('Appointment Booked','Congratulations Your Appointment has been registered' ,"success")
      }
      if(url.searchParams.get('created') == 'false'){
        Swal.fire('Appointment Not Booked','Sorry Your Appointment was not registered' ,"error")
      }
    }
    localStorage.removeItem("Ticket Data")
    localStorage.removeItem("Ticket_id")
    localStorage.removeItem("Selected Date")
    var value = document.getElementById('dataSecurity.png').dataset.value;
    document.getElementById("hover-img").style.backgroundImage="url("+ value +")"  
    window.history.replaceState(null, null, window.location.pathname);
  }
  toggleNavBar() {
    var element = document.getElementById('nav-1');
    var element2 = document.getElementById('nav-2');
    const rect = element.getBoundingClientRect();
    const inView = (
        rect.top + rect.height >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );

    if(inView){
      element.classList.remove('opacity-0')
      element.classList.remove('display-none')
      element.classList.add('opacity-100')
      element.classList.add('display-block')
      element2.classList.remove('sticky-nav-visible');
    }
    else{
      element.classList.add('opacity-0')
      element.classList.add('display-none')
      element.classList.remove('opacity-100')
      element.classList.remove('display-block')
      element2.classList.add('sticky-nav-visible');
    }



  }
  changeImage(event){
    var value = event.currentTarget.dataset.value;
    document.getElementById("hover-img").style.backgroundImage="url(" +value+ ")"
  }
  resetImage(){
    var value = document.getElementById('allbrands.png').dataset.value;
    document.getElementById("hover-img").style.backgroundImage="url("+ value +")"
  }
  redirect(){
    window.location.href = '/appointment'
  }
}