import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

document.addEventListener("DOMContentLoaded", function() {
  function checkFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    
    if (xhr.status == "404") {
        return false;
    } else {
        return true;
    }
  }
  checkServiceTitle = function(event) {
    var parent = event.currentTarget.parentElement.parentElement.parentElement
    var text = parent.children[1].children[0]
    if(text.scrollWidth > text.clientWidth) {
      text.classList.add('lh-1')
      text.classList.remove('text-nowrap')
      text.classList.remove('text-truncate')
    }
  },
  getImagePath = function(event) {
    const path = window.location.origin;
    var parent = event.currentTarget.parentElement.parentElement.parentElement
    var category = parent.dataset.categoryName.toLowerCase().split(' ').join('_')
    // var title =  parent.children[1].children[1].innerText.toLowerCase().split(' ').join('_')
    // var filepath = `/assets/service/${category}/${title}.png`;
    // if(!filepath.includes(path)){
      // filepath = path + filepath.slice(1);
    // }
    // if(checkFileExist(filepath)){
      // return filepath
    // }
    if(checkFileExist(path + `/assets/service/${category}/${category}_repair.png`)){
      return path + `/assets/service/${category}/${category}_repair.png`
    }
    else if(checkFileExist(path + `/assets/category/${category}.svg`)){
      var src = path + `/assets/category/${category}.svg`
      return src;
    }
    else if(checkFileExist(path + 'assets/category/other.svg')){
      src = path + 'assets/category/other.svg'
      return src;
    }
    else{
      return '';
    }
  }
})